import { createFileRoute, redirect, useRouter, useSearch } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../components/ui/form";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { useAuth } from "../hooks/auth";
import { REDIRECT_FALLBACK } from "../../src/constants";

export const Route = createFileRoute("/sign_in")({
  component: SignIn,
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
  }),
  beforeLoad: ({ context, search }) => {
    const { isLogged } = context.authentication;
    if (isLogged()) {
      throw redirect({ to: search.redirect || REDIRECT_FALLBACK });
    }
  },
});

const formSchema = z.object({
  email: z.string().email("Invalid email address"),
  password: z.string().min(6, "Password must be at least 6 characters"),
});

function SignIn() {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const router = useRouter();
  const { signIn } = useAuth();
  const search = useSearch({
    from: Route.fullPath,
  });

  const handleLogin = async (data: { email: string; password: string }) => {
    await signIn(data.email, data.password);
    await router.invalidate();
    await new Promise((resolve) => setTimeout(resolve, 1)); // Wait for the router to be ready. Without this, the navigate will not work.
    await router.navigate({ to: search.redirect || REDIRECT_FALLBACK });
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100">
      <div className="w-96 rounded bg-white p-6 shadow-md">
        <h2 className="mb-4 text-center text-2xl font-semibold">Login</h2>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleLogin)} className="space-y-4">
            {/* Email Field */}
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter your email" {...field} />
                  </FormControl>
                  <FormDescription>Please enter your email address</FormDescription>
                  <FormMessage>{form.formState.errors.email?.message}</FormMessage>
                </FormItem>
              )}
            />

            {/* Password Field */}
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input type="password" placeholder="Enter your password" {...field} />
                  </FormControl>
                  <FormMessage>{form.formState.errors.password?.message}</FormMessage>
                </FormItem>
              )}
            />

            {/* Submit Button */}
            <div className="pt-4">
              <Button type="submit" className="w-full bg-blue-500 hover:bg-blue-600">
                Login
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
