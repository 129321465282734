import { Deliverable } from "@/types/deliverable";
import { Link } from "@tanstack/react-router";
import { Row } from "@tanstack/react-table";
import TitleCell from "./TitleCell";

function DeliverableTitleCell({ row, getValue }: { row: Row<Deliverable>; getValue: () => string }) {
  return (
    <Link to="/deliverables/$id" params={{ id: row.original.id.toString() }}>
      <TitleCell getValue={getValue} />
    </Link>
  );
}

export default DeliverableTitleCell;
