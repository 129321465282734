import { Badge } from "./badge";
import { Command, CommandItem, CommandEmpty, CommandList } from "./command";
import { cn } from "../../lib/utils";
import { Command as CommandPrimitive } from "cmdk";
import { X as RemoveIcon, Check } from "lucide-react";
import React, { KeyboardEvent, createContext, forwardRef, useCallback, useContext, useState } from "react";
import { CheckIcon, XCircle, ChevronDown, XIcon, WandSparkles } from "lucide-react";
import * as SelectPrimitive from "@radix-ui/react-select";
import { Button } from "./button";
import { Separator } from "./separator";
import { removeAllListeners } from "nodemon";
import _ from "lodash";

type ValueProps = {
  value: number | string;
  label: string;
};
type MultiSelectorProps = {
  data: ValueProps[];
  values: string[];
  onValuesChange: (value: string[]) => void;
  loop?: boolean;
} & React.ComponentPropsWithoutRef<typeof CommandPrimitive>;
interface MultiSelectContextProps {
  data: ValueProps[];
  value: string[];
  onValueChange: (value: any) => void;
  removeAllHandler: () => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

const MultiSelectContext = createContext<MultiSelectContextProps | null>(null);

const useMultiSelect = () => {
  const context = useContext(MultiSelectContext);
  if (!context) {
    throw new Error("useMultiSelect must be used within MultiSelectProvider");
  }
  return context;
};

const MultiSelector = ({
  data,
  values: value,
  onValuesChange: onValueChange,
  loop = false,
  className,
  children,
  dir,
  ...props
}: MultiSelectorProps) => {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const onValueChangeHandler = useCallback(
    (val: string) => {
      if (value.includes(val)) {
        onValueChange(value.filter((item) => item !== val));
      } else {
        onValueChange([...value, val]);
      }
    },
    [value],
  );

  const removeAllHandler = useCallback(() => {
    onValueChange([]);
  }, [value]);

  // TODO : change from else if use to switch case statement

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      const moveNext = () => {
        const nextIndex = activeIndex + 1;
        setActiveIndex(nextIndex > value.length - 1 ? (loop ? 0 : -1) : nextIndex);
      };

      const movePrev = () => {
        const prevIndex = activeIndex - 1;
        setActiveIndex(prevIndex < 0 ? value.length - 1 : prevIndex);
      };

      if ((e.key === "Backspace" || e.key === "Delete") && value.length > 0) {
        if (inputValue.length === 0) {
          if (activeIndex !== -1 && activeIndex < value.length) {
            onValueChange(value.filter((item) => item !== value[activeIndex]));
            const newIndex = activeIndex - 1 < 0 ? 0 : activeIndex - 1;
            setActiveIndex(newIndex);
          } else {
            onValueChange(value.filter((item) => item !== value[value.length - 1]));
          }
        }
      } else if (e.key === "Enter") {
        setOpen(true);
      } else if (e.key === "Escape") {
        if (activeIndex !== -1) {
          setActiveIndex(-1);
        } else {
          setOpen(false);
        }
      } else if (dir === "rtl") {
        if (e.key === "ArrowRight") {
          movePrev();
        } else if (e.key === "ArrowLeft" && (activeIndex !== -1 || loop)) {
          moveNext();
        }
      } else {
        if (e.key === "ArrowLeft") {
          movePrev();
        } else if (e.key === "ArrowRight" && (activeIndex !== -1 || loop)) {
          moveNext();
        }
      }
    },
    [value, inputValue, activeIndex, loop],
  );

  return (
    <MultiSelectContext.Provider
      value={{
        data,
        value,
        onValueChange: onValueChangeHandler,
        removeAllHandler,
        open,
        setOpen,
        inputValue,
        setInputValue,
        activeIndex,
        setActiveIndex,
      }}>
      <Command
        onKeyDown={handleKeyDown}
        className={cn("flex flex-col space-y-2 overflow-visible bg-transparent", className)}
        dir={dir}
        {...props}>
        {children}
      </Command>
    </MultiSelectContext.Provider>
  );
};

interface MultiSelectTriggerProps extends React.HTMLAttributes<HTMLDivElement> {
  inputRef: React.RefObject<HTMLInputElement>;
}

const MultiSelectorTrigger = forwardRef<HTMLDivElement, MultiSelectTriggerProps>(
  ({ className, children, inputRef, ...props }, ref) => {
    const { data, value, onValueChange, removeAllHandler, activeIndex } = useMultiSelect();

    const mousePreventDefault = useCallback((e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    }, []);

    return (
      <div
        ref={ref}
        className={cn("flex flex-wrap gap-1 rounded-lg border border-muted bg-background px-2 py-2", className)}
        {...props}>
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-wrap items-center gap-1">
            {value.map((item, index) => {
              const itemData = _.find(data, { value: item });
              if (!itemData) return null;

              return (
                <Badge
                  key={item}
                  className={cn(
                    "flex items-center gap-1 rounded-xl px-2",
                    activeIndex === index && "ring-2 ring-muted-foreground ",
                  )}
                  variant={"secondary"}>
                  <span className="text-xs">{itemData.label}</span>
                  <button
                    aria-label={`Remove ${itemData.label} option`}
                    aria-roledescription="button to remove option"
                    type="button"
                    onMouseDown={mousePreventDefault}
                    onClick={() => onValueChange(item)}>
                    <span className="sr-only">Remove {itemData.label} option</span>
                    <RemoveIcon className="h-4 w-4 hover:stroke-destructive" />
                  </button>
                </Badge>
              );
            })}
          </div>
          {children}
          <div className="flex items-center justify-between gap-2">
            <button
              aria-label="Remove all options"
              aria-roledescription="button to remove all options"
              type="button"
              onMouseDown={mousePreventDefault}
              onClick={() => removeAllHandler()}>
              <span className="sr-only">Remove all options</span>
              <XIcon className="h-4 cursor-pointer text-muted-foreground" />
            </button>
            <Separator orientation="vertical" className="flex h-full min-h-6" />
            <div className="h-full" onClick={() => inputRef.current.focus()}>
              <ChevronDown className="h-4 cursor-pointer text-muted-foreground" />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

MultiSelectorTrigger.displayName = "MultiSelectorTrigger";

const MultiSelectorInput = forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, placeholder, ...props }, ref) => {
  const { value, setOpen, inputValue, setInputValue, activeIndex, setActiveIndex } = useMultiSelect();
  return (
    <CommandPrimitive.Input
      {...props}
      placeholder={value.length === 0 ? placeholder : ""}
      ref={ref}
      value={inputValue}
      onValueChange={activeIndex === -1 ? setInputValue : undefined}
      onBlur={() => setOpen(false)}
      onFocus={() => setOpen(true)}
      onClick={() => setActiveIndex(-1)}
      className={cn(
        "flex-1 border-none bg-transparent px-1 py-0 text-sm outline-none placeholder:text-muted-foreground focus:border-none focus:outline-none focus:ring-0",
        className,
        activeIndex !== -1 && "caret-transparent",
      )}
    />
  );
});

MultiSelectorInput.displayName = "MultiSelectorInput";

const MultiSelectorContent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ children }, ref) => {
  const { open } = useMultiSelect();
  return (
    <div ref={ref} className="relative">
      {open && children}
    </div>
  );
});

MultiSelectorContent.displayName = "MultiSelectorContent";

const MultiSelectorList = forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, children }, ref) => {
  return (
    <CommandList
      ref={ref}
      className={cn(
        "scrollbar-thin scrollbar-track-transparent scrollbar-thumb-muted-foreground dark:scrollbar-thumb-muted scrollbar-thumb-rounded-lg absolute top-0 z-10 flex w-full flex-col gap-2 rounded-md border border-muted bg-background p-2 shadow-md transition-colors",
        className,
      )}>
      {children}
      <CommandEmpty>
        <span className="text-muted-foreground">No results found</span>
      </CommandEmpty>
    </CommandList>
  );
});

MultiSelectorList.displayName = "MultiSelectorList";

const MultiSelectorItem = forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  { value: string } & React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, value, children, ...props }, ref) => {
  const { value: Options, onValueChange, setInputValue } = useMultiSelect();

  const mousePreventDefault = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const isIncluded = Options.includes(value);
  return (
    <CommandItem
      ref={ref}
      {...props}
      onSelect={() => {
        onValueChange(value);
        setInputValue("");
      }}
      className={cn(
        "flex cursor-pointer rounded-md px-2 py-1 transition-colors ",
        className,
        isIncluded && "cursor-default opacity-50",
        props.disabled && "cursor-not-allowed opacity-50",
      )}
      onMouseDown={mousePreventDefault}>
      <div className="mr-2 size-4">{isIncluded && <Check className="size-4" />}</div>
      {children}
    </CommandItem>
  );
});

MultiSelectorItem.displayName = "MultiSelectorItem";

export {
  MultiSelector,
  MultiSelectorTrigger,
  MultiSelectorInput,
  MultiSelectorContent,
  MultiSelectorList,
  MultiSelectorItem,
};
