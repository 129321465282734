import { useECharts } from "@kbox-labs/react-echarts";

const DeliverableProgressCircle = ({ donePercentage, inProgressPercentage }: { donePercentage: number, inProgressPercentage: number }) => {
  const defaultSeries = {
    type: "gauge",
    startAngle: 90,
    endAngle: -270,
    pointer: {
      show: false,
    },
    axisLine: {
      lineStyle: {
        width: 7,
      },
    },
    splitLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      show: false,
      distance: 50,
    },
    detail: {
      fontSize: 24,
      color: "inherit",
      formatter: "{value}%",
    },
    radius: "100%",
  };
  const option = {
    series: [
      {
        ...defaultSeries,
        progress: {
          show: true,
          overlap: true,
          roundCap: true,
          clip: false,
          width: 7,
          itemStyle: {
            color: "#7171F0",
          },
        },
        data: [
          {
            value: donePercentage + inProgressPercentage,
            title: {
              show: false,
            },
            detail: {
              show: false,
            },
          },
        ],
      },
      {
        ...defaultSeries,
        progress: {
          show: true,
          overlap: true,
          roundCap: true,
          clip: false,
          width: 7,
          itemStyle: {
            color: "#2DBD5D",
          },
        },
        data: [
          {
            value: donePercentage,
            title: {
              show: false,
            },
            detail: {
              show: true,
              offsetCenter: ["0%", "0%"],
              color: "black",
            },
          },
        ],
      },
    ],
  };
  const [ref] = useECharts(option);
  return <div className="h-full w-full" ref={ref} />;
};

export default DeliverableProgressCircle;
