import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { TooltipProvider } from "@/components/ui/tooltip";

function TitleCell({ getValue }: { getValue: () => string }) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="h-full w-full truncate">{getValue()}</div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{getValue()}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default TitleCell;
