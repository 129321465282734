import Title from "@/components/Title";
import Tabs from "@/components/Tabs";
import { useDeliverableData } from "@/api/Deliverables";
import { Outlet, createFileRoute } from "@tanstack/react-router";
import { Skeleton } from "@/components/ui/skeleton";

export const Route = createFileRoute("/_authenticated/deliverables/$id")({
  component: Deliverable,
});

function Deliverable() {
  const { id } = Route.useParams();
  const { data, isLoading } = useDeliverableData(id);
  if (isLoading) {
    return (
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col gap-6 bg-white p-6">
          <div className="flex gap-4">
            <Skeleton className="h-24 w-24" />
            <div className="flex flex-col items-start gap-2">
              <Skeleton className="h-6 w-24" />
              <Skeleton className="h-8 w-64" />
              <Skeleton className="h-6 w-32" />
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-6 bg-gray-100 p-6">
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-64 w-full" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-1 flex-col gap-6 bg-white p-6">
        <Title issue_key={data?.issue_key} title={data!.title} status={data!.status} type={data!.type} progress={data!.progress} />
      </div>
      <div className="flex flex-1 flex-col gap-6 bg-gray-100 p-6">
        <Tabs
          tabs={[
            { name: "Overview", to: "/deliverables/$id", activeOptions: { exact: true } },
            { name: "Work items", to: "/deliverables/$id/work_items" },
            { name: "Metrics", to: "/deliverables/$id/metrics" },
            { name: "Activity", to: "/deliverables/$id/activity" },
          ]}
        />
        <Outlet />
      </div>
    </div>
  );
}
