import { Member } from "@/types/member";
import { useQuery, useMutation, useQueryClient, UseQueryResult } from "react-query";
import client from "./client";

// Fetch all members
export const useMembersData = (): UseQueryResult<Member[]> => {
  return useQuery<Member[]>({
    queryKey: ["members"],
    queryFn: fetchMembers,
  });
};

// Fetch a single member
export const useMemberData = (memberId: number) => {
  return useQuery<Member>({
    queryKey: ["members", memberId],
    queryFn: () => fetchMember(memberId),
  });
};

// Create a new member
export const useCreateMember = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newMember: Omit<Member, "id">) => {
      const response = await client.post("/members", newMember);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
    },
  });
};

// Update an existing member
export const useUpdateMember = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ memberId, data }: { memberId: number; data: Partial<Member> }) => {
      const response = await client.patch(`/members/${memberId}`, data);
      return response.data;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
      queryClient.invalidateQueries({ queryKey: ["members", variables.memberId] });
    },
  });
};

const fetchMembers = async (): Promise<Member[]> => {
  const response = await client.get("/members");
  return response.data;
};

const fetchMember = async (memberId: number): Promise<Member> => {
  const response = await client.get(`/members/${memberId}`);
  return response.data;
};
