import { Select as UISelect, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { SelectLabel } from "@radix-ui/react-select";
import { Skeleton } from "../ui/skeleton";
import { SelectGroup as SelectGroupType, SelectOption as SelectOptionType } from "@/types/select_option";

interface GenericSelectProps {
  value: string | undefined;
  onChange: (value: string) => void;
  isLoading?: boolean;
  placeholder: string;
  groups?: SelectGroupType[];
  options?: SelectOptionType[];
}

const Select = ({ value, onChange, isLoading, placeholder, groups = [], options = [] }: GenericSelectProps) => {
  if (isLoading) {
    return <Skeleton className="h-8 w-64 rounded-full" />;
  }

  return (
    <UISelect onValueChange={onChange} value={value}>
      <SelectTrigger className="h-8 w-64 rounded-full">
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent className="p-2 shadow-select">
        {groups.length > 0 ? groups.map((group) => (
          <SelectGroup key={group.label}>
            <SelectLabel className="text-sm text-blue-800">{group.label}</SelectLabel>
            {group.options.map((option) => (
              <SelectItem className="rounded-md" key={option.value} value={option.value.toString()}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        )) : options.map((option) => (
          <SelectItem className="rounded-md" key={option.value} value={option.value.toString()}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </UISelect>
  );
};

export default Select; 