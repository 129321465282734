import { Activity, ActivityFilters } from "../types/activity";
import { useQuery, UseQueryResult } from "react-query";
import client from "./client";

// Fetch activities with optional filters
export const useActivitiesData = (filters?: ActivityFilters): UseQueryResult<Activity[]> => {
  return useQuery<Activity[]>({
    queryKey: ["activities", filters],
    queryFn: () => fetchActivities(filters),
  });
};

const fetchActivities = async (filters?: ActivityFilters): Promise<Activity[]> => {
  const response = await client.get('/activities', { params: filters });
  return response.data
};
