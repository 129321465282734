import _ from "lodash";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { MetricFormat } from "../Chart/types";

dayjs.extend(duration);

export const formatDuration = (seconds: number, settings: { showMinutes: boolean }) => {
  if (!seconds) return "0m";

  const showMinutes = settings?.showMinutes ?? true;
  const duration = dayjs.duration(seconds, "seconds");
  const years = Math.floor(duration.asYears()); // 1
  const days = Math.floor(duration.asDays()) - years * 365; // 129
  const hoursWithFraction = duration.asDays() % 1;
  const hours = Math.floor(hoursWithFraction * 24); // 0
  const minutes = Math.floor(((hoursWithFraction * 24) % 1) * 60);
  const formatted = [
    !!years && `${years}y`,
    !!days && `${days}d`,
    !!hours && `${hours}h`,
    !!minutes && showMinutes && `${minutes}m`,
  ]
    .filter(Boolean)
    .join(" ");
  return formatted;
};
export const formatUnix = (value: number) => {
  const date = dayjs.unix(value);
  if (date.month() === 0) {
    return date.format("YYYY");
  } else {
    return date.format("MMM");
  }
};
export const formatPercentage = (value: number, threashold = 0) => {
  const percentage = Math.round(value * 1000) / 10;
  if (threashold && percentage < threashold) {
    return "";
  } else {
    return percentage + "%";
  }
};

export const getLabelFormatter = (format: MetricFormat, metric: string) => {
  const formatters = {
    percentage: (params) => formatPercentage(params.value[metric], 1),
    duration: (params) => formatDuration(params.value[metric], { showMinutes: false }),
    number: (params) => params.value[metric],
  };
  return formatters[format];
};
export const getYAxisFormatter = (format: MetricFormat) => {
  const formatters = {
    percentage: (value) => formatPercentage(value),
    duration: (value: number) => formatDuration(value, { showMinutes: false }),
    number: (value: number) => value,
  };
  return formatters[format];
};
export const tooltipValueFormatter = (format: MetricFormat) => {
  const formatters = {
    percentage: (value: number) => formatPercentage(value),
    duration: (value: number) => formatDuration(value, { showMinutes: false }),
    number: (value: number) => value,
  };
  return formatters[format];
};

export const getTooltipFormatter = (format: MetricFormat) => {
  return (params) => {
    if (Array.isArray(params)) {
      const { value, encode, dimensionNames, ...rest } = params[0];
      const xAxisName = formatUnix(value[dimensionNames[encode.x[0]]]);
      const lines = params.map((param) => {
        const { value, seriesName, seriesType, marker, encode, dimensionNames, ...rest } = param;
        const encodedValue = tooltipValueFormatter(format)(value[dimensionNames[encode.y[0]]]);
        return `${marker} ${seriesName}: <span class="ml-4 float-end font-bold">${encodedValue}</span>`;
      });
      return `${xAxisName}<br/>${lines.join("<br/>")}`;
    } else {
      const { value, seriesType, marker, encode, dimensionNames, ...rest } = params;
      const encodedValue = tooltipValueFormatter(format)(value[dimensionNames[encode.y[0]]]);
      const xAxisName = formatUnix(value[dimensionNames[encode.x[0]]]);
      const identifierKey = value["issue_key"];
      return `${xAxisName}<br/>${marker} ${identifierKey}: <span class="ml-4 float-end font-bold">${encodedValue}</span>`;
    }
  };
};
