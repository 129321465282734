import { setStoredUser, getStoredToken } from "../hooks/auth";
import axios, { InternalAxiosRequestConfig } from "axios";

const client = axios.create({
  baseURL: "/api/v1",
});
const onSuccess = (response: any) => {
  return response;
};
const onError = (error: any) => {
  if (error.response.status === 401) {
    setStoredUser(null);
    window.location.href = "/sign_in";
  }
  return Promise.reject(error);
};

client.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const accessToken = getStoredToken();

  if (accessToken && config.headers) {
    config.headers["authorization"] = `Bearer ${accessToken}`;
  }

  return config;
});
client.interceptors.response.use(onSuccess, onError);

export default client;
