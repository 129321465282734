import { REDIRECT_FALLBACK } from '../../../src/constants';
import { createFileRoute, Link, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/_authenticated/')({
  beforeLoad: () => {
    throw redirect({
      to: REDIRECT_FALLBACK
    });
  },
  component: () => <Link to={REDIRECT_FALLBACK}>Go back</Link>
})
