import { Icons } from "@/components/Utils/Icons";
import _ from "lodash";
import DeliverableProgressCircle from "../DeliverableProgressCircle";
import { calculatePercentages } from "@/lib/utils";

interface TitleProps {
  issue_key: string | undefined;
  type: "components";
  title: string;
  status: string;
  progress: {
    total_count: number;
    todo_count: number;
    in_progress_count: number;
    done_count: number;
    undefined_count: number;
  };
}
const Title = ({ issue_key, title, status, type, progress }: TitleProps) => {
  const { todo_count, in_progress_count, done_count, undefined_count } = progress;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [donePercentage, inProgressPercentage, _todoPercentage, _undefinedPercentage] = calculatePercentages([done_count, in_progress_count, todo_count, undefined_count]);
  return (
    <div className="flex gap-4">
      <div className="size-24">
      <DeliverableProgressCircle donePercentage={donePercentage} inProgressPercentage={inProgressPercentage} />
    </div>
    <div className="flex flex-col items-start gap-2">
      {issue_key ? (
        <a href="#" target="_blank" rel="noopener noreferrer" className="link flex items-center gap-1">
          <span>{issue_key}</span>
          <Icons.Link size={20} />
        </a>
      ) : (
        <span>{_.upperFirst(type)}</span>
      )}
      <span className="text-2xl font-bold">{title}</span>
      <div className="min-w-[120px] rounded-xl bg-primary-400 px-5 text-center text-sm text-white">{status}</div>
    </div>
    </div>
  );
};

export default Title;