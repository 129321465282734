import { useDeliverableData } from "@/api/Deliverables";
import WorkItemsTable from "@/components/WorkItemsTable";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/deliverables/$id/work_items")({
  component: DeliverableWorkItems
});

function DeliverableWorkItems() {
  const { id } = Route.useParams();
  const { data, isLoading } = useDeliverableData(id);
  return <WorkItemsTable data={data?.work_items || []} isLoading={isLoading} />;
}
