import { Deliverable } from "../types/deliverable";
import { useQuery, UseQueryResult } from "react-query";
import client from "./client";

// Fetch all deliverables
export const useDeliverablesData = (type: string): UseQueryResult<Deliverable[]> => {
  return useQuery<Deliverable[]>({
    queryKey: ["deliverables", type],
    queryFn: () => fetchDeliverables(type),
  });
};

// Fetch a single deliverable
export const useDeliverableData = (deliverableId: number | string) => {
  return useQuery<Deliverable>({
    queryKey: ["deliverables", deliverableId],
    queryFn: async () => {
      const response = await client.get(`/deliverables/${deliverableId}`);
      return response.data;
    },
  });
};

const fetchDeliverables = async (type: string): Promise<Deliverable[]> => {
  const response = await client.get("/deliverables", { params: { type } });
  return response.data;
};
