import { createFileRoute } from "@tanstack/react-router";
import ActivityTable from "../../../../components/ActivityTable";

export const Route = createFileRoute("/_authenticated/teams/$id/activity")({
  component: Activity,
});

function Activity() {
  const { id } = Route.useParams();
  return <ActivityTable team_id={id} />;
}
