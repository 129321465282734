import { Row } from "@tanstack/react-table";
import { Activity } from "@/types/activity";
import { getActivityDescription } from "@/components/Utils/ActivityDescription";
import { TooltipContent, TooltipProvider, TooltipTrigger } from "@/ui/tooltip";
import { Tooltip } from "@/ui/tooltip";

function DescriptionCell({ row }: { row: Row<Activity> }) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={200}>
        <TooltipTrigger asChild>
          <div className="truncate">{getActivityDescription(row.original)}</div>
        </TooltipTrigger>
        <TooltipContent style={{ maxWidth: "300px", whiteSpace: "normal" }}>
          {getActivityDescription(row.original)}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default DescriptionCell;
