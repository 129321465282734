import React from "react";
import _ from "lodash";

import Spinner from "../ui/spinner";
import ChartBody from "./ChartBody";
import { useMetricData } from "../../api/metricData";
import { getRouteApi } from "@tanstack/react-router";
import { Metric, View } from "../../types/metric";
import { Switch } from "../ui/switch_mine";

const routeApi = getRouteApi("/_authenticated/metrics/$id");

const Chart = ({ metric }: { metric: Metric }) => {
  const [viewIndex, setViewIndex] = React.useState(0);
  const { id } = metric;
  const views: View[] = _.reject(metric.views, { tile_only: true });
  const onViewChange = (value: number) => {
    setViewIndex(value);
  };
  const { teamId, from, to } = routeApi.useSearch();
  const { isLoading, data, isError } = useMetricData(id, viewIndex, from, to, teamId);

  const body = () => {
    if (isLoading) {
      return <Spinner className="flex h-full items-center justify-center" />;
    } else if (isError) {
      return <div className="flex h-full items-center justify-center">Failed to load data</div>;
    } else {
      return <ChartBody rawData={data!} />;
    }
  };
  return (
    <div className="rounded-xl border bg-white">
      <div className="flex justify-between border-b px-4 py-2">
        <span className="self-center font-semibold">{metric.name}</span>
        <Switch
          options={views.map((view) => ({ label: view.name, value: view.name }))}
          onChange={onViewChange}
          selectedIndex={viewIndex}
        />
      </div>
      <div className="h-[440px] w-full p-6">{body()}</div>
    </div>
  );
};

export default Chart;
