import { calculatePercentages } from "@/lib/utils";

function ProgressCell({ getValue }: { getValue: () => { todo_count: number; in_progress_count: number; done_count: number, undefined_count: number } }) {
  const { todo_count, in_progress_count, done_count, undefined_count } = getValue();

  const [donePercentage, inProgressPercentage, todoPercentage, _undefinedPercentage] = calculatePercentages([done_count, in_progress_count, todo_count, undefined_count]);

  return (
    <div className="flex h-5 w-full overflow-hidden rounded-full font-medium text-xs">
      <div style={{ width: `${donePercentage}%` }} className="h-full bg-green-400 text-center content-center text-white">
        {donePercentage}%
      </div>
      <div style={{ width: `${inProgressPercentage}%` }} className="h-full bg-primary-400 text-center content-center text-white">
        {inProgressPercentage}%
      </div>
      <div style={{ width: `${todoPercentage}%` }} className="h-full bg-gray-200 text-center content-center text-black">
        {todoPercentage}%
      </div>
    </div>
  );
}

export default ProgressCell;
