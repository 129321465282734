import { Icons } from "../../Utils/Icons";

const KeyCell = ({ getValue }: { getValue: () => string }) => {
  return (
    <a href="#" target="_blank" rel="noopener noreferrer" className="link flex items-center gap-1 text-nowrap">
      <span>{getValue()}</span>
      <Icons.Link size={12} />
    </a>
  );
};

export default KeyCell;
