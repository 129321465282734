import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useQuery } from "react-query";
import client from "./client";
import { MetricData } from "../types/metric_data";
dayjs.extend(utc);

export const fetchMetricTileData = async (id: string | number, teamId: string | number): Promise<MetricData> => {
  const response = await client.post(`/metrics/${id}/tile_data`, {
    filters: {
      start_time: dayjs.utc("2020-01-01").format(),
      end_time: dayjs.utc("2020-07-01").format(),
      team_id: teamId,
    },
  });
  return response.data;
};

export const useMetricTileData = (id: string | number, teamId: string | number) => {
  return useQuery<MetricData>({
    queryKey: ["metric_data", id, teamId],
    queryFn: () => fetchMetricTileData(id, teamId),
  });
};
