import { useQuery, useMutation, useQueryClient, UseQueryResult } from "react-query";
import client from "./client";
import { Team } from "../types/team";

// Fetch all teams
export const useTeamsData = (): UseQueryResult<Team[]> => {
  return useQuery<Team[]>({
    queryKey: ["teams"],
    queryFn: fetchTeams,
  });
};

// Fetch a single team
export const useTeamData = (id: number | string): UseQueryResult<Team> => {
  return useQuery<Team>({
    queryKey: ["teams", id],
    queryFn: () => fetchTeam(id),
  });
};

// Create a new team
export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newTeam: Omit<Team, "id">) => {
      const response = await client.post("/teams", newTeam);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData<Team[]>(["teams"], (oldData) => [...(oldData || []), data]);
    },
  });
};

// Update an existing team
export const useUpdateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, data }: { id: number | string; data: Partial<Team> }) => {
      const response = await client.patch(`/teams/${id}`, data);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["teams", data.id], data);
      queryClient.invalidateQueries({ queryKey: ["teams"] });
    },
  });
};

// Delete a team
export const useDeleteTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number | string) => {
      await client.delete(`/teams/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["teams"] });
    },
  });
};

export const fetchTeams = async (): Promise<Team[]> => {
  const response = await client.get("/teams");
  return response.data;
};

const fetchTeam = async (id: number | string): Promise<Team> => {
  const response = await client.get(`/teams/${id}`);
  return response.data;
};
