import { Button } from "../../../../components/ui/button";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useFiltersData, useDeleteFilter } from "../../../../api/Filters";
import Spinner from "../../../../components/ui/spinner";

export const Route = createFileRoute("/_authenticated/settings/filters/")({
  component: FiltersIndex,
});

function FiltersIndex() {
  const { data: filters, isLoading } = useFiltersData();
  const { mutate } = useDeleteFilter();
  const handleDelete = (id: number) => {
    console.log("Deleting filter", id);
    mutate(id);
  };

  return (
    <div className="flex h-full flex-col items-start gap-4 p-6">
      <div className="flex w-full justify-between">
        <h1 className="text-2xl font-bold">Filters</h1>
        <Link to="/settings/filters/new" className="rounded bg-blue-500 px-4 py-2 text-white">
          Add New Filter
        </Link>
      </div>
      {isLoading ? (
        <Spinner />
      ) : filters && filters.length === 0 ? (
        <div className="text-xl text-gray-500">No filters created yet.</div>
      ) : (
        <ul className="w-full space-y-4">
          {filters &&
            filters.map((filter) => (
              <li key={filter.id} className="flex items-center justify-between rounded bg-white p-4 shadow">
                <span className="text-lg">{filter.name}</span>
                <span className="text-sm text-gray-500">{filter.query}</span>
                <div className="space-x-2">
                  <Link to={`${filter.id}`}>
                    <Button className="rounded bg-green-500 px-3 py-1 text-white">Edit</Button>
                  </Link>
                  <Button onClick={() => handleDelete(filter.id)} className="rounded bg-red-500 px-3 py-1 text-white">
                    Delete
                  </Button>
                </div>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}
