import _ from "lodash";
import { useTeamsData } from "@/api/Teams";
import Select from "@/components/Select";

const TeamSelect = ({
  teamId,
  setTeamId,
}: {
  teamId: string | undefined;
  setTeamId: (value: string) => void;
}) => {
  const { data, isLoading } = useTeamsData();

  const kinds = _.uniq(_.map(data, (team) => _.capitalize(team.kind)));
  const groups = kinds.map((kind) => {
    const teams = data.filter((team) => _.capitalize(team.kind) === kind);
    return {
      label: kind,
      options: teams.map((team) => ({
        value: team.id,
        label: team.name,
      })),
    };
  });

  return (
    <Select
      value={teamId}
      onChange={setTeamId}
      isLoading={isLoading}
      placeholder="Select the team"
      groups={groups}
    />
  );
};

export default TeamSelect;
