import { useQuery, UseQueryResult } from "react-query";
import client from "./client";
import { Field } from "../types/field";

// Fetch all fields
export const useFieldsData = (): UseQueryResult<Field[]> => {
  return useQuery<Field[]>({
    queryKey: ["fields"],
    queryFn: fetchFields,
  });
};

const fetchFields = async (): Promise<Field[]> => {
  const response = await client.get("/fields");
  return response.data;
};
