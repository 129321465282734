import _ from "lodash";
import { useMembersData } from "@/api/Members";
import { Member } from "@/types/member";
import Select from "@/components/Select";
import { Team } from "@/types/team";
import { SelectGroup as SelectGroupType } from "@/types/select_option";

const MemberSelect = ({
  memberId,
  setMemberId,
}: {
  memberId: string | undefined;
  setMemberId: (value: string) => void;
}) => {
  const { data, isLoading } = useMembersData();

  let groups: SelectGroupType[] = [];
  if (!isLoading && data) {
    const allTeams = _.flatMap(data, "teams");
    const teams = _.uniqBy(allTeams, "id");

    groups = [
      ...teams.map((team: Team) => ({
        label: team.name,
        options: data
          .filter((member: Member) => member.teams.some((t) => t.id === team.id))
          .map((member: Member) => ({
            value: member.id,
            label: member.name,
          })),
      })),
      {
        label: "No team",
        options: data
          .filter((member: Member) => member.teams.length === 0)
          .map((member: Member) => ({
            value: member.id,
            label: member.name,
          })),
      },
    ];
  }

  return (
    <Select
      value={memberId}
      onChange={setMemberId}
      isLoading={isLoading}
      placeholder="Select the member"
      groups={groups}
    />
  );
};

export default MemberSelect;
