import { RouterProvider, createRouteMask, createRouter } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "./components/ui/toaster";
import { useAuth, AuthProvider } from "./hooks/auth";
import { HOUR } from "./constants";

const membersZeroToMembersMask = createRouteMask({
  routeTree,
  from: "/members/0",
  to: "/members",
  unmaskOnReload: true,
});

const deliverablesZeroToDeliverablesMask = createRouteMask({
  routeTree,
  from: "/deliverables/epics",
  to: "/deliverables",
  unmaskOnReload: true,
});

const router = createRouter({
  routeTree,
  routeMasks: [membersZeroToMembersMask, deliverablesZeroToDeliverablesMask],
  context: { authentication: undefined! },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const InnerApp = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: HOUR,
        gcTime: HOUR * 24,
        retry: (failureCount, error) => {
          console.log("retry", failureCount, error)
          return failureCount < 1
        },
      },
    },
  });
  const authentication = useAuth();
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} context={{ authentication }} />
      <Toaster />
    </QueryClientProvider>
  )
}

const App = () => {
  return (
    <AuthProvider>
      <InnerApp />
    </AuthProvider>
  )
}

export default App;