import StatusCell from "../Table/cells/StatusCell";
import ProgressCell from "../Table/cells/ProgressCell";
import DeliverableTitleCell from "../Table/cells/DeliverableTitleCell";
import { DateCell } from "../Table/cells/DateCell";
import Table from "../Table";
import { Deliverable } from "@/types/deliverable";

const columns = [
  {
    accessorKey: "title",
    header: "Title",
    size: 400,
    cell: DeliverableTitleCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "status",
    header: "Status",
    size: 160,
    cell: StatusCell,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "progress",
    header: "Progress",
    enableSorting: false,
    size: 200,
    cell: ProgressCell,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "target_date",
    header: "Target",
    size: 140,
    cell: DateCell,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "forecast_date",
    header: "Forecast",
    size: 140,
    cell: DateCell,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
];

const DeliverablesTable = ({ data, isLoading, isError }: { data: Deliverable[] | undefined, isLoading: boolean, isError: boolean }) => {
  if (isError) return <div>Error</div>;

  return (
    <Table data={data || []} columns={columns} isLoading={isLoading}>
      <Table.Header name="Deliverables">
        <Table.Search />
      </Table.Header>
      <Table.Main />
      <Table.Pagination />
    </Table>
  );
};
export default DeliverablesTable;
