import { Filter } from "../types/filter";
import { useQuery, useMutation, useQueryClient, UseQueryResult } from "react-query";
import client from "./client";

// Fetch all filters
export const useFiltersData = (): UseQueryResult<Filter[]> => {
  return useQuery<Filter[]>({
    queryKey: ["filters"],
    queryFn: fetchFilters,
  });
};

// Fetch a single filter
export const useFilterData = (filterId: number | string) => {
  return useQuery<Filter>({
    queryKey: ["filters", filterId],
    queryFn: async () => {
      const response = await client.get(`/filters/${filterId}`);
      return response.data;
    },
  });
};

// Create a new filter
export const useCreateFilter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newFilter: Omit<Filter, "id">) => {
      const response = await client.post("/filters", newFilter);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["filters"] });
    },
  });
};

// Update an existing filter
export const useUpdateFilter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ filterId, data }: { filterId: number | string; data: Partial<Filter> }) => {
      const response = await client.put(`/filters/${filterId}`, data);
      return response.data;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ["filters"] });
      queryClient.invalidateQueries({ queryKey: ["filters", variables.filterId] });
    },
  });
};

// Delete a filter
export const useDeleteFilter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (filterId: number) => {
      await client.delete(`/filters/${filterId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["filters"] });
    },
  });
};

const fetchFilters = async (): Promise<Filter[]> => {
  const response = await client.get("/filters");
  return response.data;
};
