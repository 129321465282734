import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import Sidebar from "./-sidebar";
import { AuthContext } from "../hooks/auth";

type RouterContext = {
  authentication: AuthContext;
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <>
      <Sidebar />
      <main className="pl-20">
        <Outlet />
      </main>
    </>
  ),
});
