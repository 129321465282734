import { useReactTable } from "@tanstack/react-table";
import React from "react";
export interface TableContextType {
  table: ReturnType<typeof useReactTable>;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}

export const TableContext = React.createContext<TableContextType>(undefined!)

export const useTableContext = (): TableContextType => {
  const context = React.useContext(TableContext);
  if (context === undefined) {
    throw new Error("useTableContext must be used within a TableProvider");
  }
  return context;
};
