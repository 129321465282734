import { Link } from "@tanstack/react-router";
import { Row } from "@tanstack/react-table";
import { WorkItem } from "@/types/work_item";

function AssigneeCell({ row }: { row: Row<WorkItem> }) {
  return (
    <Link to="/members/$id" params={{ id: row.original.assignee_key }} className="link">
      <div className="h-full w-full">{row.original.assignee_name}</div>
    </Link>
  );
}

export default AssigneeCell;
