import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFieldArray } from "react-hook-form";
import { z } from "zod";

import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../components/ui/select";
import { Button } from "../../../../components/ui/button";
import {
  MultiSelector,
  MultiSelectorContent,
  MultiSelectorInput,
  MultiSelectorItem,
  MultiSelectorList,
  MultiSelectorTrigger,
} from "../../../../components/ui/multi-select";
import _ from "lodash";
import { Icons } from "../../../../components/Utils/Icons";
import { Switch } from "../../../../components/ui/switch_mine";
import { FilterCondition, FilterGroup } from "../../../../../src/types/filter";
import { useFieldsData } from "../../../../api/Fields";
import Spinner from "../../../../components/ui/spinner";

const operators = [
  { label: "=", value: "=" },
  { label: ">", value: ">" },
  { label: "<", value: "<" },
  { label: "in", value: "in" },
  { label: "between", value: "between" },
];

const filterFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(50, {
      message: "Name must not be longer than 50 characters.",
    }),
  filter_group: z.object({
    id: z.number().optional(),
    combinator: z.enum(["and", "or"]),
    conditions: z.array(
      z.object({
        id: z.number().optional(),
        field: z.string().min(1, { message: "Field is required." }),
        operator: z.enum(_.map(operators, "value"), {
          message: "Operator is required.",
        }),
        value: z.string().min(1, { message: "Value is required." }),
      }),
    ),
  }),
});

interface FormFilterProps {
  defaultValues: {
    name: string;
    filter_group: FilterGroup;
  };
  onSubmit: (data: { name: string; filter_group: FilterGroup }) => void;
  SubmitText: string;
  ResetText?: string;
}

const FormFilter: React.FC<FormFilterProps> = ({ defaultValues, onSubmit, SubmitText, ResetText }) => {
  const form = useForm({
    resolver: zodResolver(filterFormSchema),

    defaultValues: defaultValues,
  });
  const errors = form.formState.errors;
  React.useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const { data: fields, isLoading: isFieldsLoading } = useFieldsData();

  const {
    fields: queryFields,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: "filter_group.conditions",
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="Filter Name" {...field} />
              </FormControl>
              <FormDescription>
                This is a name of the filter. It will be displayed in the selects and other places.
              </FormDescription>
              <FormMessage>{errors.name?.message}</FormMessage>
            </FormItem>
          )}
        />

        <FormField
          key={`filter_group.id`}
          control={form.control}
          name={`filter_group.id`}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input type="hidden" {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <div className="flex flex-col gap-2">
          {queryFields.map((item, index) => (
            <>
              <div key={item.id} className="flex items-center gap-2">
                <div className="w-32">
                  {index > 0 ? (
                    <FormField
                      key={`filter_group.combinator.${index}`}
                      control={form.control}
                      name={`filter_group.combinator`}
                      render={({ field }) => (
                        <FormItem>
                          <Switch
                            options={[
                              { label: "AND", value: "and" },
                              { label: "OR", value: "or" },
                            ]}
                            onChange={(value) => field.onChange(value === 0 ? "and" : "or")}
                            selectedIndex={field.value === "and" ? 0 : 1}
                          />
                        </FormItem>
                      )}
                    />
                  ) : (
                    <span className="font-bold">Where</span>
                  )}
                </div>

                <FormField
                  key={`filter_group.conditions.${index}.id`}
                  control={form.control}
                  name={`filter_group.conditions.${index}.id`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input type="hidden" {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  key={`filter_group.conditions.${index}.field`}
                  control={form.control}
                  name={`filter_group.conditions.${index}.field`}
                  render={({ field }) => (
                    <FormItem>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className="min-w-64">
                            <SelectValue placeholder="Field" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {isFieldsLoading ? (
                            <Spinner />
                          ) : (
                            fields!.map((field) => (
                              <SelectItem key={field.key} value={field.key}>
                                {field.key}
                              </SelectItem>
                            ))
                          )}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />

                <FormField
                  key={`filter_group.conditions.${index}.operator`}
                  control={form.control}
                  name={`filter_group.conditions.${index}.operator`}
                  render={({ field }) => (
                    <FormItem>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className="min-w-32">
                            <SelectValue placeholder="Operator" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {operators.map((operator) => (
                            <SelectItem key={operator.value} value={operator.value}>
                              {operator.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />

                <FormField
                  key={`filter_group.conditions.${index}.value`}
                  control={form.control}
                  name={`filter_group.conditions.${index}.value`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input {...field} placeholder="Value" className="min-w-64" />
                      </FormControl>
                    </FormItem>
                  )}
                />
                {queryFields.length > 1 && (
                  <Button
                    key={`filter_group.conditions.${index}.remove`}
                    variant="ghost"
                    type="button"
                    onClick={() => remove(index)}
                    className="text-gray-500">
                    <Icons.Trash />
                  </Button>
                )}
              </div>

              <FormMessage key={`filter_group.conditions.${index}.field`}>
                {errors.filter_group?.conditions?.[index]?.field?.message}
              </FormMessage>
              <FormMessage key={`filter_group.conditions.${index}.operator`}>
                {errors.filter_group?.conditions?.[index]?.operator?.message}
              </FormMessage>
              <FormMessage key={`filter_group.conditions.${index}.value`}>
                {errors.filter_group?.conditions?.[index]?.value?.message}
              </FormMessage>
            </>
          ))}
        </div>

        <Button
          type="button"
          variant="outline"
          onClick={() => append({ field: "", operator: "", value: "" })}
          className="flex gap-2 text-gray-500">
          <Icons.Plus size={16} />
          Add Condition
        </Button>

        <div className="space-x-2">
          {ResetText && (
            <Button type="button" variant="secondary" onClick={() => form.reset(defaultValues)}>
              {ResetText}
            </Button>
          )}
          <Button type="submit">{SubmitText}</Button>
        </div>
      </form>
    </Form>
  );
};
export default FormFilter;
