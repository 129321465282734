import { createFileRoute, useNavigate } from '@tanstack/react-router'
import FormTeam from "./-FormTeam";
import Spinner from "@/components/ui/spinner";
import { useCreateTeam } from "@/api/Teams";
import { useMembersData } from "@/api/Members";

export const Route = createFileRoute("/_authenticated/settings/teams/new")({
  component: NewTeam,
});

function NewTeam() {
  let memberOptions = [];

  const { data: members, isLoading: isMembersLoading } = useMembersData();
  const { mutate } = useCreateTeam();
  const navigate = useNavigate();
  if (isMembersLoading) {
    return <Spinner />;
  } else {
    memberOptions = members.map((member) => {
      return {
        value: member.id.toString(),
        label: member.name,
      };
    });
  }

  const onSubmit = (data) => {
    mutate(data, {
      onSuccess: (team) => {
        navigate({ to: "/settings/teams/$id", params: { id: team.id } });
      }
    });
  }

  return <FormTeam onSubmit={onSubmit} defaultValues={{}} memberOptions={memberOptions} SubmitText="Create Team" />;
}
