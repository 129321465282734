import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "../../ui/tooltip";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const DateCell = ({ getValue }: { getValue: () => string }) => {
  return <span>{dayjs(getValue()).format("MMM D YYYY")}</span>;
};

const RelativeDateCell = ({ getValue }: { getValue: () => string }) => {
  const date = dayjs(getValue());
  return (
    <TooltipProvider>
      <Tooltip delayDuration={200}>
        <TooltipTrigger>{date.fromNow()}</TooltipTrigger>
        <TooltipContent>
          <p>{date.format("MMM D YYYY HH:mm")}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export { DateCell, RelativeDateCell };
