import React from "react";

const statusColors = {
  "To Do": "bg-gray-200 text-black",
  Open: "bg-gray-200 text-black",
  Scheduled: "bg-gray-200 text-black",
  "Needs Scope": "bg-gray-200 text-black",
  "In Progress": "bg-primary-400 text-white",
  Implementing: "bg-primary-400 text-white",
  Scoping: "bg-primary-400 text-white",
  Designing: "bg-primary-400 text-white",
  Done: "bg-green-400 text-white",
  Closed: "bg-green-400 text-white",
  Resolved: "bg-green-400 text-white",
  Idle: "bg-orange-500 text-white",
  "Waiting (Blocked)": "bg-orange-500 text-white",
};

const StatusCell = ({ getValue }: { getValue: () => string }) => {
  const status = getValue();
  const colorClasses = statusColors[status] || "bg-gray-500 text-white";
  return <div className={`w-[120px] rounded-xl text-center ${colorClasses}`}>{status}</div>;
};

export default StatusCell;
