import Tabs from "@/components/Tabs";
import MemberSelect from "@/components/MemberSelect";
import { Outlet, createFileRoute, useNavigate, useRouterState } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/members/$id")({
  component: Member,
});

function Member() {
  const { id } = Route.useParams();
  const navigate = useNavigate();
  const routerState = useRouterState();
  
  const changeMemberId = (id: string) => {
    navigate({ 
      to: routerState.matches[routerState.matches.length - 1].fullPath, 
      params: { id }, 
      replace: true 
    });
  };

  return (
    <div className="flex flex-1 flex-col gap-6 bg-gray-100 p-6">
      <MemberSelect memberId={id} setMemberId={changeMemberId} />
      <Tabs
        tabs={[
          { name: "Overview", to: "/members/$id", activeOptions: { exact: true } },
          { name: "Metrics", to: "/members/$id/metrics" },
          { name: "Activity", to: "/members/$id/activity" },
        ]}
      />
      <Outlet />
    </div>
  );
}
