import { QueryFunctionContext, useQuery } from "react-query";
import client from "./client";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { MetricData } from "../types/metric_data";

dayjs.extend(utc);

export const fetchMetricData = async (
  id: string | number,
  viewIndex: number,
  from: string,
  to: string,
  teamId: string | number
): Promise<MetricData> => {
  const start_time = dayjs.utc(from).startOf("day").format("YYYY-MM-DDTHH:mm:ssZ");
  const end_time = dayjs.utc(to).endOf("day").format("YYYY-MM-DDTHH:mm:ssZ");
  const response = await client.post(`/metrics/${id}/data`, {
    view_index: viewIndex,
    filters: {
      start_time,
      end_time,
      team_id: teamId,
    },
  });
  return response.data;
};

export const useMetricData = (
  id: string | number,
  viewIndex: number,
  from: string,
  to: string,
  teamId: string | number
) => {
  return useQuery<MetricData, Error>({
    queryKey: ["metric_data", id, viewIndex, from, to, teamId],
    queryFn: (context: QueryFunctionContext) =>
      fetchMetricData(id, viewIndex, from, to, teamId),
  });
};