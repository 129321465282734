import ActivityTable from "@/components/ActivityTable";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/deliverables/$id/activity")({
  component: Activity,
});

function Activity() {
  const { id } = Route.useParams();
  return <ActivityTable deliverable_id={id} />;
}
