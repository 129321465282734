import { Icons } from "../../../../components/Utils/Icons";
import WorkItemsWithActivityTable from "../../../../components/WorkItemsWithActivityTable";
import { cn } from "../../../../lib/utils";
import { createFileRoute, useParams } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/members/$id/")({
  component: Member,
});

function Member() {
  return (
    <>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <div className="overflow-scroll rounded-xl bg-white p-4">
          <MetricsTable />
        </div>
        <div className="overflow-scroll rounded-xl bg-white p-4">
          <MetricsTable />
        </div>
      </div>
      <WorkItemsWithActivityTable />
    </>
  );
}

const MetricsTable = () => {
  return (
    <table className="w-full text-left">
      <thead>
        <tr className="text-nowrap text-sm font-semibold leading-8 text-gray-500">
          <th className="pr-4">Metric</th>
          <th className="pr-4 text-right">Value</th>
          <th className="max-w-10 text-right">Team avg.</th>
        </tr>
      </thead>
      <tbody>
        <Metric
          name="# of finished work items"
          value={8}
          difference={25}
          color="green"
          movement="raise"
          team_avarage={11}
          label="top"
        />
        <Metric
          name="# of finished work items"
          value={8}
          difference={10}
          color="red"
          movement="stable"
          team_avarage={11}
          label="average"
        />
        <Metric name="# of bugs" value={5} difference={15} color="red" movement="fall" team_avarage={7} label="below" />
        <Metric
          name="# of code reviews"
          value={10}
          difference={30}
          color="green"
          movement="raise"
          team_avarage={8}
          label="top"
        />
        <Metric
          name="# of test cases"
          value={15}
          difference={5}
          color="green"
          movement="raise"
          team_avarage={12}
          label="top"
        />
        <Metric
          name="# of user stories"
          value={20}
          difference={20}
          color="green"
          movement="stable"
          team_avarage={18}
          label="average"
        />
        <Metric
          name="# of feature requests"
          value={7}
          difference={35}
          color="red"
          movement="fall"
          team_avarage={9}
          label="below"
        />
        <Metric
          name="# of design documents"
          value={12}
          difference={40}
          color="green"
          movement="raise"
          team_avarage={10}
          label="top"
        />
        <Metric
          name="# of meetings"
          value={4}
          difference={30}
          color="red"
          movement="fall"
          team_avarage={6}
          label="below"
        />
        <Metric
          name="# of deployments"
          value={3}
          difference={15}
          color="green"
          movement="stable"
          team_avarage={4}
          label="average"
        />
      </tbody>
    </table>
  );
};

interface MetricProps {
  name: string;
  value: number;
  difference: number;
  color: string;
  movement: string;
  team_avarage: number;
  label: string;
}
const Metric = ({ name, value, difference, color, movement, team_avarage, label }: MetricProps) => {
  let MovementIcon = () => <span>&mdash;</span>;
  if (movement === "raise") {
    MovementIcon = () => <Icons.TrendingUp size={16} />;
  } else if (movement === "fall") {
    MovementIcon = () => <Icons.TrendingDown size={16} />;
  }
  let labelText;
  if (label === "top") {
    labelText = "High";
  } else if (label === "average") {
    labelText = "Medium";
  } else if (label === "below") {
    labelText = "Low";
  }

  return (
    <tr className="my-2 leading-10">
      <td className="pr-4">{name}</td>
      <td className="pr-4">
        <div className="flex items-center justify-end gap-4">
          {/* <%# <div className="leading-normal w-20 text-center text-white bg-green-400 rounded-full border border-green-700">Top</div> */}
          <div
            className={cn("rounded-full border px-2 leading-normal text-white", {
              "border-green-700 bg-green-500": label === "top",
              "border-yellow-400 bg-yellow-200 text-black": label === "average",
              "border-red-600 bg-red-400": label === "below",
            })}>
            {labelText}
          </div>
          <b>{value}</b>
          <span
            className={cn("flex items-center gap-x-1", {
              "text-green-500": color === "green",
              "text-red-500": color === "red",
            })}>
            <MovementIcon />
            <span className="inline-block text-sm">{difference}%</span>
          </span>
          {/* <div
            className={cn("h-5 w-10 rounded-full border", {
              "border-yellow-500 bg-yellow-200 text-black": label === "average",
              "border-red-700 bg-red-400": label === "below",
              "border-green-700 bg-green-500": label === "top",
            })}></div> */}
        </div>
      </td>
      <td className="text-right">{team_avarage}</td>
    </tr>
  );
};
