import * as echarts from "echarts";
import _ from "lodash";
import { useECharts } from "@kbox-labs/react-echarts";
import { formatUnix, getLabelFormatter, getYAxisFormatter, getTooltipFormatter } from "../Utils/Formatters";
import { percentageTransform, nullifyTransform } from "./transformations";
import { MetricProps } from "./types";

echarts.registerTransform(percentageTransform);
echarts.registerTransform(nullifyTransform);

interface ChartBodyProps {
  rawData: {
    result: MetricProps[];
  };
}

const transformationsForMetric = (metric: MetricProps) => {
  let additionalTransforms = [];
  if (metric.format === "percentage") {
    additionalTransforms.push({
      transform: {
        type: "andonPulse:percent",

        config: {
          dimension: "__timestamp",
          metric: "sum",
        },
      },
    });
  }
  if (metric.group_by && metric.group_by.length === 2) {
    additionalTransforms.push({
      fromDatasetIndex: additionalTransforms.length,
      transform: {
        type: "andonPulse:nullify",
        config: {
          dimensions: metric.group_by,
          metric: "sum",
        },
      },
    });
    const customGroup = metric.group_by[1];
    const uniqGroups = _.map(_.uniqBy(metric.dataset.source, customGroup), customGroup);
    const additionalTransformationsSize = additionalTransforms.length;
    uniqGroups.forEach((group) => {
      additionalTransforms.push({
        id: additionalTransforms.length,
        fromDatasetIndex: additionalTransformationsSize,
        transform: {
          type: "filter",
          config: { dimension: customGroup, "=": group },
        },
      });
    });
  }
  return additionalTransforms;
};

const transformations = (metrics: MetricProps[]): any[] => {
  return _.flatMap(metrics, (metric: MetricProps) => transformationsForMetric(metric));
};

const getDataset = (metrics: MetricProps[]): { dimensions: string[]; source: Object[] }[] => {
  return _.map(metrics, (metric: MetricProps) => _.pick(metric.dataset, ["dimensions", "source"]));
};

const datasetWithTransformations = (metrics: MetricProps[]) => {
  return [...getDataset(metrics), ...transformations(metrics)];
};

const getOneSeriesData = (metric: MetricProps, metricIndex: number, transformations: any[]) => {
  let seriesData = [];
  const filters = _.filter(transformations, { transform: { type: "filter" } });
  const isScatter = metric.type === "scatter";
  const defaultOptions = {
    type: metric.type,
    encode: {
      x: "__timestamp",
      y: metric.value,
    },
    label: {
      show: !isScatter,
      formatter: getLabelFormatter(metric.format, metric.value),
    },
  };
  if (filters.length !== 0) {
    const additionalTransformationsSize = transformations.length - filters.length;
    seriesData = _.map(filters, (filter, index) => {
      return {
        ...defaultOptions,
        datasetIndex: additionalTransformationsSize + index + 1, // TODO: Set the correct dataset index. Based on dataset count + transformations count + filter index
        name: filter.transform.config["="],
        stack: "stack",
        areaStyle: metric.area ? {} : undefined,
      };
    });
  } else {
    seriesData = [
      {
        ...defaultOptions,
        datasetIndex: metricIndex,
        name: metric.value,
      },
    ];
  }
  return seriesData;
};

const getSeriesData = (metrics: MetricProps[]) => {
  return _.flatMap(metrics, (metric: MetricProps, index: number) => {
    return getOneSeriesData(metric, index, transformationsForMetric(metric));
  });
};

const LEGENDRIGHT = 8;
const LEGENDITEMWIDTH = 32;
const LEGENDWIDTH = 120;
const LEGENDTEXTPADDING = 8;

const gridPosition = () => {
  const right = LEGENDRIGHT + LEGENDITEMWIDTH + LEGENDWIDTH + LEGENDTEXTPADDING;
  return {
    left: 16,
    right: right,
    bottom: 0,
    top: 16,
  };
};

const ChartBody = ({ rawData }: ChartBodyProps) => {
  const metrics = rawData.result;
  const dataset = datasetWithTransformations(metrics);
  const series = getSeriesData(metrics);

  const valueType = metrics[0].format;
  const isScatter = _.some(metrics, { type: "scatter" });
  const isBar = _.some(metrics, { type: "bar" });
  const tooltipTrigger = isScatter ? "item" : "axis";
  const axisPointerType = isBar ? "shadow" : "line";
  const yAxisFormatter = getYAxisFormatter(valueType);
  const [ref, _echartsInstance] = useECharts({
    series: series,
    dataset: dataset,
    renderer: "svg",
    tooltip: {
      trigger: tooltipTrigger,
      formatter: getTooltipFormatter(valueType),
      axisPointer: {
        type: axisPointerType,
      },
    },
    grid: {
      containLabel: true,
      ...gridPosition(),
    },
    legend: {
      type: "scroll",
      orient: "vertical",
      right: LEGENDRIGHT,
      align: "left",
      itemWidth: LEGENDITEMWIDTH,
      itemHeight: 12,
      itemGap: 16,
      top: "middle",
      icon: "path://M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6C32 9.31371 29.3137 12 26 12H6C2.68629 12 0 9.31371 0 6Z",
      textStyle: {
        width: LEGENDWIDTH,
        padding: [0, 0, 0, LEGENDTEXTPADDING - 5],
        overflow: "truncate",
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: isBar ? true : false,
      splitLine: {
        show: true,
      },
      axisTick: {
        show: true,
        length: 8,
      },
      axisPointer: {
        label: {
          formatter: (params) => formatUnix(params.value),
        },
        lineStyle: {
          color: "#999",
          cap: "round",
        },
      },
      axisLabel: {
        formatter: formatUnix,
      },
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, "0%"],
      max: valueType === "percentage" ? "dataMax" : undefined,
      axisLine: {
        show: true,
      },
      axisTick: {
        show: true,
        length: 8,
      },
      splitLine: {
        show: true,
      },
      axisLabel: {
        margin: 12,
        formatter: yAxisFormatter,
      },
    },
  });

  return <div className="h-full w-full" ref={ref} />;
};

export default ChartBody;
