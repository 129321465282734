import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function calculatePercentages(numbers: number[]) {
  const total = numbers.reduce((sum: number, num: number) => sum + num, 0);

  // Calculate raw percentages
  let percentages = numbers.map((num: number) => (num / total) * 100);

  // Round to 1 decimal place
  percentages = percentages.map((p: number) => Math.round(p * 10) / 10);

  // Handle rounding errors to ensure sum is 100%
  const sum = percentages.reduce((sum: number, p: number) => sum + p, 0);
  const diff = 100 - sum;
  
  // Add any rounding difference to the largest percentage
  if (diff !== 0) {
      const maxIndex = percentages.indexOf(Math.max(...percentages));
      percentages[maxIndex] = +(percentages[maxIndex] + diff).toFixed(1);
  }
  
  return percentages;
}