import * as React from "react";

import { cn } from "../../lib/utils";

export interface SwitchProps {
  options: { label: string; value: string }[];
  onChange: (value: number) => void;
  selectedIndex: number;
}

const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  ({ options, onChange, selectedIndex, ...props }: SwitchProps, ref) => {
    return options.length > 1 ? (
      <div className="flex w-fit divide-x divide-primary-200 overflow-hidden rounded-2xl border border-primary-200 bg-white">
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => onChange(index)}
            type="button"
            className={cn("px-3 py-1", { "bg-primary-500 text-white": index === selectedIndex })}>
            {option.label}
          </button>
        ))}
      </div>
    ) : null;
  },
);

export { Switch };
