import { Link, Outlet } from "@tanstack/react-router";
import SidebarNav from "@/components/SidebarNav";
import { fetchTeams } from "@/api/Teams";
import _ from "lodash";
import { Skeleton } from "@/components/ui/skeleton";
import { createFileRoute } from "@tanstack/react-router";
import { Icons } from "@/components/Utils/Icons";
import { SidebarTrigger } from "@/components/ui/sidebar";
import { SidebarProvider } from "@/components/ui/sidebar";

export const Route = createFileRoute("/_authenticated/settings")({
  component: Settings,
  loader: () => fetchTeams(),
  pendingComponent: SettingsLoading,
});

function Settings() {
  const data = Route.useLoaderData();

  const teamsItems = data.map((team) => ({
    title: team.name,
    href: `/settings/teams/${team.id}`,
  }));

  const sidebarNavItems = [
    {
      title: "Integrations",
      icon: Icons.Integration,
      subItems: [
        { title: "Jira", href: "/settings/integrations/jira", icon: Icons.Jira },
        { title: "Github", href: "/settings/integrations/github", icon: Icons.Github },
      ],
    },
    {
      title: "Teams",
      icon: Icons.Team,
      action: (
        <Link to="/settings/teams/new">
          <Icons.Plus size={16} className="size-4" /> <span className="sr-only">Add Team</span>
        </Link>
      ),
      subItems: teamsItems,
    },
    {
      title: "Filters",
      href: "/settings/filters",
      icon: Icons.ListFilter,
    },
  ];

  return (
    <div className="flex h-screen w-full">
      <SidebarProvider>
        <SidebarNav items={sidebarNavItems} />
        {/* <SidebarInset> */}
          <div className="flex h-full flex-shrink-0 flex-grow flex-col gap-4 overflow-y-scroll bg-white p-6">
            <SidebarTrigger />
            <Outlet />
          </div>
        {/* </SidebarInset> */}
      </SidebarProvider>
    </div>
  );
}

function SettingsLoading() {
  return (
    <div className="h-screen w-full">
      <div className="grid h-full grid-cols-5">
        <div className="col-span-1 flex h-full flex-col gap-4 overflow-y-scroll bg-gray-100 p-6">
          <div className="space-y-6">
            <Skeleton className="h-6 bg-gray-200" />
            <Skeleton className="h-6 bg-gray-200" />
            <Skeleton className="h-6 bg-gray-200" />
            <Skeleton className="h-6 bg-gray-200" />
            <Skeleton className="h-6 bg-gray-200" />
          </div>
        </div>
        <div className="col-span-4 flex h-full flex-col gap-4 overflow-y-scroll bg-white p-6"></div>
      </div>
    </div>
  );
}
