import React from "react";
import { useState } from "react";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableContext } from "./TableProvider";
import TableHeader from "./TableHeader";
import TableSearch from "./TableSearch";
import TableMain from "./TableMain";
import TablePagination from "./TablePagination";
import { Skeleton } from "../ui/skeleton";

const TableComponent: React.FC<{
  columns: any[];
  data: any[];
  isLoading: boolean;
  children: React.ReactNode;
  defaultPageSize?: number;
}> = ({ columns, data, isLoading, children, defaultPageSize }) => {
  const [filter, setFilter] = useState<string>("");

  const tableData = React.useMemo(() => (isLoading ? Array(10).fill({}) : data), [isLoading, data]);
  const tableColumns = React.useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton className="h-5 w-full" />,
          }))
        : columns,
    [isLoading, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    state: {
      globalFilter: filter,
    },
    onGlobalFilterChange: setFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: defaultPageSize || 10,
      },
    },
  });
  const contextProps = {
    table: table,
    filter: filter,
    setFilter: setFilter,
  };

  return (
    <TableContext.Provider value={contextProps}>
      <div className="rounded-xl bg-white">{children}</div>
    </TableContext.Provider>
  );
};
const Table = Object.assign(TableComponent, {
  Header: TableHeader,
  Search: TableSearch,
  Main: TableMain,
  Pagination: TablePagination,
});
export default Table;
