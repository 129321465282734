import { Icons } from "./Icons";

const Tooltip = () => {
  return (
    <div className="hs-tooltip">
      <div className="hs-tooltip-toggle">
        <Icons.CircleHelp size={16} />
        <span
          className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible invisible absolute z-10 inline-block rounded bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity dark:bg-neutral-700"
          role="tooltip">
          The number of daily users
        </span>
      </div>
    </div>
  );
};

export default Tooltip;
