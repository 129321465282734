import {createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/metrics/")({
  component: MetricsIndex,
});

function MetricsIndex() {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-4">
      <div className="text-2xl text-gray-500">
        Let's find some answers! Choose a question or start typing in the search bar.
      </div>
      <div className="text-xl text-gray-500">Search for answers using questions or metrics</div>
    </div>
  );
}
