import Tabs from "@/components/Tabs";
import TeamSelect from "@/components/TeamSelect";
import { Outlet, createFileRoute, useNavigate, useRouterState } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/teams/$id")({
  component: Team,
});

function Team() {
  const { id } = Route.useParams();
  const navigate = useNavigate();
  const routerState = useRouterState();
  const changeTeamId = (id: string) => {
    navigate({ to: routerState.matches[routerState.matches.length - 1].fullPath, params: { id }, replace: true });
  };
  return (
    <div className="flex flex-1 flex-col gap-6 bg-gray-100 p-6">
      <TeamSelect teamId={id} setTeamId={changeTeamId} />
      <Tabs
        tabs={[
          { name: "Overview", to: "/teams/$id", activeOptions: { exact: true } },
          { name: "Metrics", to: "/teams/$id/metrics" },
          { name: "Activity", to: "/teams/$id/activity" },
        ]}
      />
      <Outlet />
    </div>
  );
}
