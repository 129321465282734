import { cn } from "../../../../lib/utils";
import { createFileRoute, useParams } from "@tanstack/react-router";
import MetricTile from "../../../../components/MetricTile";
import Table from "../../../../components/Table";
import KeyCell from "../../../../components/Table/cells/KeyCell";
import DATA from "../../../../components/DeliverablesTable/data";
import { Popover, PopoverContent, PopoverTrigger, PopoverArrow } from "../../../../components/ui/popover";
import { Icons } from "../../../../components/Utils/Icons";
import SprintsChart from "../../../../components/SprintsChart";

export const Route = createFileRoute("/_authenticated/teams/$id/")({
  component: Team,
});

function Team() {
  const { id } = Route.useParams();
  const metrics = [
    { id: 5, name: "Lead Time" },
    { id: 6, name: "Cycle Time" },
  ];
  const needsAttentions = {
    "In progress": [
      {
        key: "TEL-1746",
        title: "Add redirection in InContact",
        reason: "Stalled for 3 days in 'In development' status",
        state: "Needs Attention",
      },
      {
        key: "TEL-1752",
        title: "Add allowlist of phone numbers",
        reason: "No progress detected in the last 2 days",
        state: "Needs Attention",
      },
      {
        key: "TEL-1754",
        title: "Change cadence criteria to allow redirection",
        reason: "Has low chance to be delivered on time",
        state: "On Target",
      },
      {
        key: "TEL-1766",
        title: "Refactor redirection script",
        reason: "Overdue the target date for 1 day",
        state: "On Target",
      },
    ],
    "Ready for review": [
      {
        key: "TEL-1747",
        title: "Add redirection in InContact",
        reason: "Stalled for 3 days in 'In development' status",
        state: "Needs Attention",
      },
      {
        key: "TEL-1755",
        title: "Change cadence criteria to allow redirection",
        reason: "Has low chance to be delivered on time",
        state: "At Risk",
      },
      {
        key: "TEL-1767",
        title: "Refactor redirection script",
        reason: "Overdue the target date for 1 day",
        state: "On Target",
      },
    ],
    "In Review": [
      {
        key: "TEL-1753",
        title: "Remove validation from Customer Support numbers",
        reason: "Stuck in review for 28 hours",
        state: "Needs Attention",
      },
      {
        key: "TEL-1756",
        title: "Change cadence criteria to allow redirection",
        reason: "Has low chance to be delivered on time",
        state: "Needs Attention",
      },
      {
        key: "TEL-1768",
        title: "Refactor redirection script",
        reason: "Overdue the target date for 1 day",
        state: "On Target",
      },
    ],
    "Ready for testing": [
      {
        key: "TEL-1748",
        title: "Add redirection in InContact",
        reason: "Stalled for 3 days in 'In development' status",
        state: "At Risk",
      },
      {
        key: "TEL-1757",
        title: "Add allowlist of phone numbers",
        reason: "No progress detected in the last 2 days",
        state: "At Risk",
      },
      {
        key: "TEL-1758",
        title: "Remove validation from Customer Support numbers",
        reason: "Stuck in review for 28 hours",
        state: "At Risk",
      },
      {
        key: "TEL-1759",
        title: "Change cadence criteria to allow redirection",
        reason: "Has low chance to be delivered on time",
        state: "On Target",
      },
      {
        key: "TEL-1769",
        title: "Refactor redirection script",
        reason: "Overdue the target date for 1 day",
        state: "On Target",
      },
    ],
    "In testing": [
      {
        key: "TEL-1749",
        title: "Add redirection in InContact",
        reason: "Stalled for 3 days in 'In development' status",
        state: "On Target",
      },
      {
        key: "TEL-1760",
        title: "Add allowlist of phone numbers",
        reason: "No progress detected in the last 2 days",
        state: "On Target",
      },
      {
        key: "TEL-1761",
        title: "Change cadence criteria to allow redirection",
        reason: "Has low chance to be delivered on time",
        state: "Needs Attention",
      },
      {
        key: "TEL-1770",
        title: "Refactor redirection script",
        reason: "Overdue the target date for 1 day",
        state: "Needs Attention",
      },
    ],
    "Ready for release": [
      {
        key: "TEL-1750",
        title: "Add redirection in InContact",
        reason: "Stalled for 3 days in 'In development' status",
        state: "Needs Attention",
      },
      {
        key: "TEL-1762",
        title: "Remove validation from Customer Support numbers",
        reason: "Stuck in review for 28 hours",
        state: "At Risk",
      },
      {
        key: "TEL-1763",
        title: "Change cadence criteria to allow redirection",
        reason: "Has low chance to be delivered on time",
        state: "On Target",
      },
      {
        key: "TEL-1764",
        title: "Change cadence criteria to allow redirection",
        reason: "Has low chance to be delivered on time",
        state: "On Target",
      },
      {
        key: "TEL-1765",
        title: "Change cadence criteria to allow redirection",
        reason: "Has low chance to be delivered on time",
        state: "On Target",
      },
      {
        key: "TEL-1771",
        title: "Refactor redirection script",
        reason: "Overdue the target date for 1 day",
        state: "On Target",
      },
    ],
    Released: [
      {
        key: "TEL-1751",
        title: "Change cadence criteria to allow redirection",
        reason: "Has low chance to be delivered on time",
        state: "On Target",
      },
      {
        key: "TEL-1772",
        title: "Refactor redirection script",
        reason: "Overdue the target date for 1 day",
        state: "On Target",
      },
    ],
  };
  const columns = [
    {
      accessorKey: "key",
      header: "Key",
      maxSize: 120,
      cell: KeyCell,
      enableColumnFilter: true,
      enableGlobalFilter: true,
      filterFn: "includesString",
    },
    {
      accessorKey: "title",
      header: "Title",
      size: 400,
      cell: (props) => <p>{props.getValue()}</p>,
      enableColumnFilter: true,
      enableGlobalFilter: true,
      filterFn: "includesString",
    },
    {
      accessorKey: "reason",
      header: "Reason",
      size: 400,
      cell: (props) => <p>{props.getValue()}</p>,
      enableColumnFilter: true,
      enableGlobalFilter: true,
      filterFn: "includesString",
    },
  ];

  return (
    <div className="grid grid-cols-3 gap-6">
      <div className="col-span-2 flex flex-col gap-6">
        <div className="overflow-hidden rounded-xl bg-white">
          <div className="flex flex-col self-stretch border-b px-4 py-2">
            <div className="text-base font-semibold">Work items that need attention</div>
            <div className="text-xs text-gray-600">Identify tasks at risk of missing deadlines</div>
          </div>
          <div className="flex flex-col gap-4 border-b p-4">
            <div className="flex flex-row gap-4">
              <div className="flex flex-col">
                <div className="text-red-600">Needs Attention</div>
                <div>5</div>
              </div>
              <div className="flex flex-col">
                <div className="text-yellow-400">At Risk</div>
                <div>9</div>
              </div>
              <div className="flex flex-col">
                <div className="text-green-600">On Target</div>
                <div>12</div>
              </div>
              <div className="flex flex-col">
                <div>Total</div>
                <div>26</div>
              </div>
            </div>
            <div className="flex gap-6">
              {Object.entries(needsAttentions).map(([status, issues]) => (
                <div key={status} className="flex flex-1 flex-col gap-2 overflow-hidden">
                  <div className="truncate font-semibold">{status}</div>
                  <div className="flex flex-wrap gap-2">
                    {issues.map((issue) => (
                      <Popover key={issue.key}>
                        <PopoverTrigger>
                          <div
                            className={cn("size-4 rounded border", {
                              "border-red-600 bg-red-500": issue.state === "Needs Attention",
                              "border-yellow-400 bg-yellow-300": issue.state === "At Risk",
                              "border-green-500 bg-green-400": issue.state === "On Target",
                            })}></div>
                        </PopoverTrigger>
                        <PopoverContent className="shadow-md">
                          <PopoverArrow className="-translate-y-[1px] fill-white stroke-gray-200" />
                          <div className="flex flex-col gap-2">
                            <a href="#" className="link flex items-baseline gap-1">
                              <span className="text-sm">{issue.key}</span>
                              <Icons.Link size={12} />
                            </a>
                            <span className="font-bold">{issue.title}</span>
                            <div className="flex gap-1">
                              <Icons.CircleAlert size={20} className="icon" />
                              <span className="text-sm">{issue.reason}</span>
                            </div>
                          </div>
                        </PopoverContent>
                      </Popover>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="relative overflow-x-auto">
            <Table data={DATA} columns={columns} defaultPageSize={5}>
              <Table.Main />
              {DATA.length > 5 && <Table.Pagination />}
            </Table>
          </div>
        </div>
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {metrics.map((metric) => (
            <div key={metric.id} className="flex min-h-32 flex-col rounded-xl bg-white dark:border-neutral-700 dark:bg-neutral-800">
              <MetricTile metricId={metric.id} teamId={id} />
            </div>
          ))}
        </div>
        <SprintsChart />
      </div>
      <div className="col-span-1">
        <CycleTimeBreakdown />
      </div>
    </div>
  );
}

const CycleTimeBreakdown = () => {
  return (
    <div className="overflow-hidden rounded-xl bg-white">
      <div className="flex flex-col self-stretch border-b px-4 py-2">
        <div className="text-base font-semibold">Cycle time breakdown</div>
        <div className="text-xs text-gray-600">
          Track where the team is spending the most time to identify bottlenecks
        </div>
        <b className="text-4xl">7.5 days</b>
      </div>
      <div className="p-4">
        <div className="relative flex gap-2">
          <div className="grow pb-4">
            <div className="flex justify-between">
              <div className="mb-1 text-sm font-normal leading-none">Coding Time</div>
              <div className="mb-1 text-sm font-normal leading-none text-gray-500 dark:text-gray-500">
                Moved to In Progress
              </div>
            </div>
            <div className="flex w-full gap-1.5">
              <div className="h-3 rounded-full bg-blue-600 dark:bg-blue-500" style={{ width: "90%" }}></div>
              <div className="self-start text-sm font-bold leading-none">31%</div>
            </div>
          </div>
          <div className="dark:team-hover:after:bg-neutral-600 relative after:absolute after:bottom-0 after:end-1.5 after:top-3 after:w-px after:translate-x-[0.5px] after:bg-gray-200 dark:after:bg-neutral-700">
            <div className="relative z-10 flex justify-end">
              <div className="size-3 rounded-full border-2 border-blue-400 bg-white dark:border-neutral-600 dark:bg-neutral-800"></div>
            </div>
          </div>
        </div>
        <div className="relative flex gap-2">
          <div className="grow pb-4">
            <div className="flex justify-between">
              <div className="mb-1 text-sm font-normal leading-none">Pickup Time</div>
              <div className="mb-1 text-sm font-normal leading-none text-gray-500 dark:text-gray-500">
                Review requested
              </div>
            </div>
            <div className="flex w-full gap-1.5">
              <div className="h-3 rounded-full bg-blue-600 dark:bg-blue-500" style={{ width: "35%" }}></div>
              <div className="self-start text-sm font-bold leading-none">12%</div>
            </div>
          </div>
          <div className="dark:team-hover:after:bg-neutral-600 relative after:absolute after:bottom-0 after:end-1.5 after:top-3 after:w-px after:translate-x-[0.5px] after:bg-gray-200 dark:after:bg-neutral-700">
            <div className="relative z-10 flex justify-end">
              <div className="size-3 rounded-full border-2 border-blue-400 bg-white dark:border-neutral-600 dark:bg-neutral-800"></div>
            </div>
          </div>
        </div>
        <div className="relative flex gap-2">
          <div className="grow pb-4">
            <div className="flex justify-between">
              <div className="mb-1 text-sm font-normal leading-none">Fixing Time</div>
              <div className="mb-1 text-sm font-normal leading-none text-gray-500 dark:text-gray-500">
                Review submitted
              </div>
            </div>
            <div className="flex w-full gap-1.5">
              <div className="h-3 rounded-full bg-blue-600 dark:bg-blue-500" style={{ width: "49.3%" }}></div>
              <div className="self-start text-sm font-bold leading-none">17%</div>
            </div>
          </div>
          <div className="dark:team-hover:after:bg-neutral-600 relative after:absolute after:bottom-0 after:end-1.5 after:top-3 after:w-px after:translate-x-[0.5px] after:bg-gray-200 dark:after:bg-neutral-700">
            <div className="relative z-10 flex justify-end">
              <div className="size-3 rounded-full border-2 border-blue-400 bg-white dark:border-neutral-600 dark:bg-neutral-800"></div>
            </div>
          </div>
        </div>
        <div className="relative flex gap-2">
          <div className="grow pb-4">
            <div className="flex justify-between">
              <div className="mb-1 text-sm font-normal leading-none">Time to deploy on staging</div>
              <div className="mb-1 text-sm font-normal leading-none text-gray-500 dark:text-gray-500">
                Approve received
              </div>
            </div>
            <div className="flex w-full gap-1.5">
              <div className="h-3 rounded-full bg-blue-600 dark:bg-blue-500" style={{ width: "14.5%" }}></div>
              <div className="self-start text-sm font-bold leading-none">5%</div>
            </div>
          </div>
          <div className="dark:team-hover:after:bg-neutral-600 relative after:absolute after:bottom-0 after:end-1.5 after:top-3 after:w-px after:translate-x-[0.5px] after:bg-gray-200 dark:after:bg-neutral-700">
            <div className="relative z-10 flex justify-end">
              <div className="size-3 rounded-full border-2 border-blue-400 bg-white dark:border-neutral-600 dark:bg-neutral-800"></div>
            </div>
          </div>
        </div>
        <div className="relative flex gap-2">
          <div className="grow pb-4">
            <div className="flex justify-between">
              <div className="mb-1 text-sm font-normal leading-none">QA time</div>
              <div className="mb-1 text-sm font-normal leading-none text-gray-500 dark:text-gray-500">
                Moved to Ready for QA
              </div>
            </div>
            <div className="flex w-full gap-1.5">
              <div className="h-3 rounded-full bg-blue-600 dark:bg-blue-500" style={{ width: "66.77%" }}></div>
              <div className="self-start text-sm font-bold leading-none">23%</div>
            </div>
          </div>
          <div className="dark:team-hover:after:bg-neutral-600 relative after:absolute after:bottom-0 after:end-1.5 after:top-3 after:w-px after:translate-x-[0.5px] after:bg-gray-200 dark:after:bg-neutral-700">
            <div className="relative z-10 flex justify-end">
              <div className="size-3 rounded-full border-2 border-blue-400 bg-white dark:border-neutral-600 dark:bg-neutral-800"></div>
            </div>
          </div>
        </div>
        <div className="relative flex gap-2">
          <div className="grow pb-4">
            <div className="flex justify-between">
              <div className="mb-1 text-sm font-normal leading-none">Deploy Time</div>
              <div className="mb-1 text-sm font-normal leading-none text-gray-500 dark:text-gray-500">QA finished</div>
            </div>
            <div className="flex w-full gap-1.5">
              <div className="h-3 rounded-full bg-blue-600 dark:bg-blue-500" style={{ width: "34.83%" }}></div>
              <div className="self-start text-sm font-bold leading-none">12%</div>
            </div>
          </div>
          <div className="dark:team-hover:after:bg-neutral-600 relative after:absolute after:bottom-0 after:end-1.5 after:top-3 after:w-px after:translate-x-[0.5px] after:bg-gray-200 dark:after:bg-neutral-700">
            <div className="relative z-10 flex justify-end">
              <div className="size-3 rounded-full border-2 border-blue-400 bg-white dark:border-neutral-600 dark:bg-neutral-800"></div>
            </div>
          </div>
        </div>
        <div className="relative flex gap-2">
          <div className="grow">
            <div className="float-right mb-1 text-sm font-normal leading-none text-gray-500 dark:text-gray-500">
              Released
            </div>
          </div>
          <div className="size-3 rounded-full border-2 border-blue-400 bg-white dark:border-neutral-600 dark:bg-neutral-800"></div>
        </div>
      </div>
    </div>
  );
};
