import { Link } from "@tanstack/react-router";
import { Row } from "@tanstack/react-table";
import { Activity } from "@/types/activity";

function AuthorCell({ row }: { row: Row<Activity> }) {
  return (
    <Link to="/members/$id" params={{ id: row.original.member.id }} className="link">
      <div className="h-full w-full">{row.original.member.name}</div>
    </Link>
  );
}

export default AuthorCell;
