interface User {
  id: number;
  jti: string;
  token: string;
  email: string;
}

const API_URL = "/api/v1";

export const signInUser = async (credentials: { email: string; password: string }): Promise<User> => {
  const response = await fetch(`${API_URL}/sign_in`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: { email: credentials.email, password: credentials.password } }),
  });

  const body = await response.json();
  return body.data;
};

export const signOutUser = async (): Promise<void> => {
  const token = localStorage.getItem("auth.token");
  const response = await fetch(`${API_URL}/sign_out`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
  });
  return response.json();
};
