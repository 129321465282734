import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { signInUser, signOutUser } from "../api/auth";
import { NullUser, User } from "../../src/types/user";

export interface AuthContext {
  isLogged: () => boolean
  signIn: (email: string, password: string) => Promise<void>
  signOut: () => Promise<void>
  user: User | NullUser
}

const AuthContext = createContext<AuthContext | null>(null)

const key = 'auth.user'
const tokenKey = 'auth.token'

function getStoredUser(): User | NullUser {
  const user = localStorage.getItem(key)
  return user ? JSON.parse(user) : { id: undefined, jti: undefined, token: undefined, email: undefined }
}

export function setStoredUser(user: User | null) {
  if (user) {
    localStorage.setItem(key, JSON.stringify(user))
    localStorage.setItem(tokenKey, user.token)
  } else {
    localStorage.removeItem(key)
    localStorage.removeItem(tokenKey)
  }
}

export function getStoredToken(): string | null {
  return localStorage.getItem(tokenKey)
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | NullUser>(getStoredUser())

  const signOut = useCallback(async () => {
    await signOutUser();
    setStoredUser(null)
    setUser({ id: undefined, jti: undefined, token: undefined, email: undefined } as NullUser)
  }, [])

  const isLogged = () => {
    return !!getStoredToken()
  }

  const signIn = useCallback(async (email: string, password: string) => {
    const data = await signInUser({ email, password });
    if (data.token) {
      setStoredUser(data)
      setUser(data as User)
    }
  }, [])

  useEffect(() => {
    setUser(getStoredUser())
  }, [])

  return (
    <AuthContext.Provider value={{ isLogged, user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContext {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
