import KeyCell from "../Table/cells/KeyCell";
import Table from "../Table";
import { RelativeDateCell } from "../Table/cells/DateCell";
import { useActivitiesData } from "@/api/Activity";
import DescriptionCell from "../Table/cells/DescriptionCell";
import AuthorCell from "../Table/cells/AuthorCell";

const columns = [
  {
    accessorKey: "issue_key",
    header: "Key",
    size: 140,
    cell: KeyCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "member",
    header: "Author",
    size: 140,
    cell: AuthorCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "description",
    header: "Description",
    size: 400,
    cell: DescriptionCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "created_at",
    header: "Created",
    size: 140,
    cell: RelativeDateCell,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
];

const ActivityTable = (props: { team_id?: number | string; member_id?: number | string; deliverable_id?: number | string }) => {
  const { data = [], isLoading, isError } = useActivitiesData(props);

  if (isError) return <div>Error</div>;

  return (
    <Table data={data} columns={columns} isLoading={isLoading}>
      <Table.Header name="Activity">
        <Table.Search />
      </Table.Header>
      <Table.Main />
      <Table.Pagination />
    </Table>
  );
};
export default ActivityTable;
